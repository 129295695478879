module.exports = {
	defaultTitle: 'David Kensell, LA based front-end web developer',
	logo: 'https://www.davidkensell.com/favicon/favicon.ico',
	author: 'David Kensell',
	url: 'https://www.davidkensell.com',
	legalName: 'David Kensell',
	defaultDescription: 'It\'s Dave! Los Angeles based front-end web developer (Javascript! React! Ruby! Static sites! Mobile responsiveness!) with decade+ in the Internet biz.',
	socialLinks: {
		twitter: '',
		github: 'https://github.com/davidkensell',
		linkedin: 'https://www.linkedin.com/in/davidkensell/',
		instagram: '',
		youtube: '',
		google: '',
	},
	googleAnalyticsID: 'UA-56515167-5',
	themeColor: '#6b63ff',
	backgroundColor: '#6b63ff',
	social: {
		facebook: 'appId',
		twitter: '',
	},
	address: {
		city: 'Los Angeles',
		region: 'California',
		country: 'USA',
		zipCode: '90230',
	},
	contact: {
		email: 'dk@davidkensell.com',
		phone: '',
	},
	foundingDate: '2019',
	recaptcha_key: '6Le7FZMUAAAAAN60lSdEstAXtqpZQz6YP1Ijy7SA',
}